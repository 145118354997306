import { environment, isLocal } from 'App/Helpers/enviroments';

const env = environment();
let config;

const baseConfig = {
    buildVersion: '1.1.4.12',
    adProvider: 'dev', // change 'ad provider' name in the corresponding offer on the sharpstar dashboard
    lang: 'pt',
    isStage: true,
    sourceName: 'Bacanagol',
    gameAnalyticsKey: '82028d998441cfbbcd773ef236b10e9c', // link to Game Analytics app - https://tool.gameanalytics.com/game/284703/
    gameAnalyticsSecret: '673cf8464f7a602dd2e022928ff8d6e797c19e08',
    sentryEnabled: true,
    modalsUrl: 'https://enjoythegames.info/wp-json/wp/v2/pages/?slug=',
    terms: 'https://enjoythegames.info/wp-json/wp/v2/pages/?slug=termos-de-uso',
    privacy:
        'https://enjoythegames.info/wp-json/wp/v2/pages/?slug=politica-de-privacidade',
};

const local = {
    ...baseConfig,
    rid: 115,
    campaignId: 38,
    source: `${baseConfig.sourceName} Local`,
    sharpstarApiUrl: 'https://dcdev.sharpstar.pro/',
    domain: 'http://127.0.0.1:8080/',
    buildPath: '',
    oneSignalAppId: 'cd43657e-9a63-4013-b24e-fa3486f748b0', // OneSignal local app name: Localhost CY Development
    safariWebId: 'web.onesignal.auto.01b20842-ed7c-48c4-bd42-e78491d78625',
    gaTrackId: 'G-7QMGB80HXS', // Google Analytics app name: Games-Dev-ALL; stream name: Games Dev
};

const dev = {
    ...baseConfig,
    rid: 146,
    campaignId: 63,
    source: `${baseConfig.sourceName} Dev`,
    sharpstarApiUrl: 'https://dcdev.sharpstar.pro/',
    domain: 'https://stage.bacanagol.com',
    buildPath: 'https://stage.bacanagol.com/build/',
    oneSignalAppId: '879f4b42-ffe0-4fc2-8779-35ef106c32ee', // OneSignal stage app name: Development BR Development
    safariWebId: 'web.onesignal.auto.01d22b73-bccb-4a84-9633-69c73285f3f4',
    gaTrackId: 'G-7QMGB80HXS', // Google Analytics app name: Games-Dev-ALL; stream name: Games Dev
};

const prod = {
    ...baseConfig,
    isStage: false,
    rid: 127,
    campaignId: 43,
    source: `${baseConfig.sourceName} Prod`,
    adProvider: 'vli',
    sharpstarApiUrl: 'https://dc.sharpstar.pro/',
    domain: 'https://bacanagol.com',
    buildPath: 'https://bacanagol.com/game/',
    oneSignalAppId: '7a89f563-c9a6-42ec-9117-8595e45bde77', // OneSignal prod app name: Games_web BR Production
    safariWebId: 'web.onesignal.auto.37bbdda8-1be5-416a-8d2a-3d51b0669a43',
    gaTrackId: 'G-EYRMRLHD2R', // Google Analytics app name: Football-BR
    gameAnalyticsKey: 'a54d03eeebbf057b8b486126e7e395a5', // link to Game Analytics app - https://tool.gameanalytics.com/game/266513/
    gameAnalyticsSecret: '98131f4980849c8fa568756d53984384d0d5565a',
};

if (env === 'production') {
    config = prod;
} else if (env === 'development') {
    config = dev;
} else {
    config = local;
}

export const oneSignalConfig = {
    appId: config.oneSignalAppId,
    safari_web_id: config.safariWebId,
    serviceWorkerPath: isLocal()
        ? 'OneSignalSDKWorker.js'
        : 'push/OneSignalSDKWorker.js',
    serviceWorkerParam: {
        scope: isLocal() ? '/' : '/push/',
    },
    notifyButton: {
        enable: false,
    },
    welcomeNotification: {
        disable: true,
    },
    allowLocalhostAsSecureOrigin: isLocal(),
    autoRegister: false,
};

export const rewardIdsMap = {
    Plus500Coins: 'pw_33449',
    Plus100Coins: 'pw_33450',
    Plus1Bag: 'pw_33451',
    GetThirdKey: 'pw_33452',
    CollectAndSkipLevel: 'pw_33453',
    MultiplyCoins: 'pw_33454',
    ExtraProgress: 'pw_33455',
    SkipLevel: 'pw_41631',
    ReplayLevel: 'pw_41804',
};

export default config;
